import * as React from "react";
import classNames from "clsx";
import { buttonCss, buttonVariantsCss } from "./Button.css";

export const Button: React.FC<{
  as?: React.ElementType;
  variant: "primary" | "inverse";
  className?: string;
}> = function Button({
  as: Component = "button",
  variant = "primary",
  className,
  ...props
}) {
  return (
    <Component
      className={classNames(buttonCss, buttonVariantsCss[variant], className)}
      {...props}
    />
  );
};
