/* global gtag */
import * as React from "react";
import { graphql } from "gatsby";
import { useTitle, useMeta } from "hoofd";
import { Button } from "../components/Button";
import { Strapline } from "../components/Heading";
import { Row } from "../modules/Grid/Row";
import { ContentBlock } from "../modules/ContentBlock/index.tsx";
import { Container } from "../components/Container.tsx";
import {
  MastHead,
  Headline,
  BackgroundImage,
} from "../modules/MastHead/index.ts";
import { Spacer } from "../components/Spacer";
import DefaultLayout from "../layout/default";
import { sectionCss } from "../atomics.css";
import {
  actionBarCss,
  iframeCss,
  rowGapCss,
  inputFieldCss,
  inputCss,
  textareaCss,
  fullHeightCss,
} from "./contact.css";

// markup
const ContactPage = ({
  data: { contentfulPage, contentfulContactInformation },
}) => {
  const gmapRef = React.useRef(null);
  const [isSending, setSending] = React.useState(false);
  const [msg, setMessage] = React.useState(null);
  const onSubmit = React.useCallback(function onSubmit(e) {
    e.preventDefault();
    setSending(true);

    const formBag = Object.create(null);
    [...e.target.elements].forEach((item) => {
      if (item.name) {
        formBag[item.name] = item.value;
      }
    });

    gtag("event", "click", {
      event_category: "general",
      event_label: "mail",
      value: formBag.email,
    });

    fetch("/api/contact", {
      method: "post",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formBag),
    })
      .then((res) => {
        return res.json();
      })
      .then(({ ok, error }) => {
        setSending(false);
        setMessage({
          error: !ok,
          message: ok ? "Je bericht is met success verstuurd." : error,
        });
      })
      .catch(() => {
        setSending(false);
      });
  }, []);

  React.useEffect(() => {
    let gmapEl = gmapRef.current;
    let timerRef = setTimeout(() => {
      const iframe = document.createElement("iframe");
      iframe.title = `${contentfulContactInformation.street} - ${contentfulContactInformation.postalcode} ${contentfulContactInformation.city}`;
      iframe.width = 1080;
      iframe.height = 500;
      iframe.src = `https://maps.google.com/maps?q=${encodeURI(
        contentfulContactInformation.street
      )}%2C${contentfulContactInformation.postalcode}%20${
        contentfulContactInformation.city
      }&t=&z=15&ie=UTF8&iwloc=&output=embed&language=nl`;
      iframe.className = iframeCss;
      iframe.referrerPolicy = "no-referrer-when-downgrade";

      gmapEl.appendChild(iframe);
    }, 0);

    return () => {
      if (timerRef) {
        clearTimeout(timerRef);
      }

      const iframe = gmapEl.querySelector("iframe");
      if (iframe) {
        gmapEl.removeChild(iframe);
      }
    };
  }, [contentfulContactInformation]);

  useTitle(contentfulPage.pageTitle || contentfulPage.title);
  useMeta({ name: "description", content: contentfulPage.description });

  return (
    <DefaultLayout>
      <MastHead>
        <Strapline theme="light">{contentfulPage.strapline}</Strapline>
        <Spacer size="extraLarge" />
        <Headline title={contentfulPage.title} />
        <BackgroundImage
          image={
            contentfulPage.titleBackground?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          alt=""
          role="presentation"
        />
      </MastHead>

      {contentfulPage.contentBlocks.map((node, index) => {
        return (
          <section key={node.id} className={sectionCss}>
            <Container>
              <ContentBlock
                layout={index % 2 === 0 ? "default" : "swap"}
                title={node.title}
                strapline={node.strapline}
                description={node.description}
                imageDescription={node.image.title}
                imageLoading="eager"
                image={node.image?.localFile.childImageSharp.gatsbyImageData}
              />
            </Container>
          </section>
        );
      })}

      <section
        style={{ background: "#d8dee7", paddingTop: 100, paddingBottom: 100 }}
      >
        <Container>
          <form method="post" action="/api/contact" onSubmit={onSubmit}>
            {msg ? (
              <div style={msg.error ? { color: "#ff0033" } : {}}>
                <strong>{msg.message}</strong>
              </div>
            ) : null}
            <p>
              Laat hier een bericht na. Wij nemen zo snel mogelijk contact met
              je op.
            </p>
            <Row className={rowGapCss}>
              <div>
                <div className={inputFieldCss}>
                  <label htmlFor="name" style={{ display: "none" }}>
                    Naam
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="naam"
                    className={inputCss}
                    required
                  />
                </div>
                <div className={inputFieldCss}>
                  <label htmlFor="phone" style={{ display: "none" }}>
                    Telefoonnummer
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="telefoonnummer"
                    className={inputCss}
                    required
                  />
                </div>
                <div className={inputFieldCss}>
                  <label htmlFor="email" style={{ display: "none" }}>
                    e-mailadres
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="e-mailadres"
                    className={inputCss}
                    required
                  />
                </div>
              </div>
              <div>
                <div className={fullHeightCss}>
                  <label htmlFor="message" style={{ display: "none" }}>
                    Bericht
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="bericht"
                    className={textareaCss}
                    required
                  ></textarea>
                </div>
              </div>
            </Row>

            <Spacer size="extraLarge" />
            <div className={actionBarCss}>
              <input type="text" name="_gotcha" style={{ display: "none" }} />
              <Button type="submit" disabled={isSending}>
                Verzenden
              </Button>
            </div>
          </form>
        </Container>
      </section>

      <section ref={gmapRef}></section>
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    contentfulContactInformation {
      street
      postalcode
      city
    }

    contentfulPage(slug: { eq: "contact" }) {
      title
      strapline
      titleBackground {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, height: 242)
          }
        }
      }
      pageTitle
      description
      contentBlocks {
        ... on ContentBlock {
          __typename
          id
          title
          description
        }
        ... on ContentfulServiceBlock {
          strapline
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 550
                  placeholder: TRACED_SVG
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default ContactPage;
